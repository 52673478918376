angular.module('cofidis.controllers')
        .controller('PromotionsListCtrl', function ($scope, $http, $uibModal, $state, Promotions, Document, Category, SweetAlert, GlobalService) {
            $scope.$root.promotions = Promotions.query();
            $scope.callbackMoved = function (index) {
                $scope.$root.promotions.splice(index, 1);
                console.log($scope.$root.promotions);
                $http.post(GlobalService.apiBaseUrl + "/promotions/promotionsorder/", $scope.$root.promotions, {cache: false})
                        .then(
                                function (response) {
                                    // success callback

                                },
                                function (response) {
                                    // failure callback

                                }
                        );
            };
            $scope.deletePromotion = function (user) {
                SweetAlert.swal(
                        {
                            title: "Sei sicuro?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Si, cancella!",
                            cancelButtonText: "Annulla",
                            closeOnConfirm: false
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                user.$delete(function () {
                                    SweetAlert.swal("Promozione cancellata!", "", "success");
                                    var index = $scope.$root.promotions.indexOf(user);
                                    $scope.$root.promotions.splice(index, 1);
                                });
                            }
                        }
                );
            };
        })
        .controller('PromotionsInfoCtrl', function ($stateParams, $http, $scope, $uibModal, $state, Promotions, Document, Category, SweetAlert, GlobalService) {
            var promotionId = $stateParams.promotionId;
            $http.get(GlobalService.apiBaseUrl + "/promotion/promotioninfo/" + promotionId, {cache: false})
                    .then(function (response) {
                        console.log(response);
                        $scope.$root.promotionInfo = response.data;
                    });
            $scope.updatePromotionInfo = function (notizia) {
                var message = "Riquadro Promozione  aggiornato!";
                //return;
                if (notizia.id == 0)
                {
                    message = "Riquadro Promozione aggiornato!";
                }

                $http.post(GlobalService.apiBaseUrl + "/promotion/promotioninfo/" + promotionId, notizia, {cache: false})
                        .then(
                                function (response) {
                                    // success callback
                                    SweetAlert.swal(message, "", "success");
                                },
                                function (response) {
                                    // failure callback
                                    SweetAlert.swal(message, "", "success");

                                    GlobalService.clearErrors();
                                    GlobalService.addErrors(response.data, 'module');
                                }
                        );
            };
        })
        .controller('PromotionsIndexCtrl', function ($stateParams, $scope, $rootScope, $uibModal, $state, Promotions, News, Module, PromotionById, SweetAlert, Document, Category, FileUploader, GlobalService)
        {
            //Get last news id
            var promotionId = $stateParams.promotionId;

            $scope.$root.lastnews = Promotions.query();
            //get News by Title
            var NotiziaJson = PromotionById.query({id: promotionId});
            //Checks presence of no records in table
            PromotionById.query({id: promotionId}).$promise.then(function (data) {
                $scope.$root.lastnews = data;
                /*console.log($scope.$root.lastnews);*/
                console.log(data);
                if (typeof data[0] != "undefined")
                    if (!data[0].id) {
                        data[0].id = 0;
                    }
            });

            $scope.updatePromotion = function (notizia) {
                var message = "Promozione  aggiornata!";
                //uploader mobile
                var itm = $scope.uploader.queue[0];
                if (typeof itm != "undefined")
                {
                    notizia.namefilem = itm.file.name;
                    notizia.sizefilem = itm.file.size;
                    // console.log(itm.file.name);
                } else
                {
                    notizia.namefilem = null;
                    notizia.sizefilem = null;
                }
                //uploader desktop
                var itd = $scope.uploader_d.queue[0];
                if (typeof itd != "undefined")
                {
                    notizia.namefiled = itd.file.name;
                    notizia.sizefiled = itd.file.size;
                } else
                {
                    notizia.namefiled = null;
                    notizia.sizefiled = null;
                }
                //return;
                if (notizia.id == 0)
                {
                    message = "Promozione  aggiornata!";
                }
                //angular resource
                notizia.$update(function () {
                    GlobalService.clearErrors();
                    $("#edit_news div.field").removeClass("has-error");
                    SweetAlert.swal(message, "", "success");
                }, function (xhr) {
                    GlobalService.clearErrors();
                    GlobalService.addErrors(xhr.data, 'module');
                }
                );
            };
            //start Image Upload Mobile
            $scope.document = new Document();
            $scope.folders = GlobalService.getFolders();
            $scope.categories = Category.query();
            $scope.choosen_drop = [];
            $scope.useFolders = GlobalService.useFolders;
            $scope.useFolders = false;
            $scope.uploader_queue_length = 0;
            $scope.loaded = 0;
            $scope.apiBaseUrl = GlobalService.apiBaseUrl;
            $scope.blogHome = GlobalService.blogHome;

            $scope.uploader = new FileUploader({
                url: GlobalService.apiBaseUrl + '/documents',
                scope: $scope,
                alias: 'attachment',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                filters: [
                    {
                        name: 'filetype',
                        fn: function (item /*{File|FileLikeObject}*/, options) {
                            var type = '|' + item.name.slice(item.name.lastIndexOf('.') + 1).toLowerCase() + '|';
                            var allowed_types = [
                                'jpg', 'png', 'jpeg', 'bmp', 'gif',
                            ];
                            return ('|' + allowed_types.join('|') + '|').indexOf(type) !== -1;
                        }
                    }
                ],
                onAfterAddingFile: function (item) {
                    //remove old 
                    if ($scope.uploader.queue.length == 2)
                    {
                        var index = 0;
                        $scope.uploader.queue.splice(index, 1);
                    }
                    $scope.loaded = 0;
                    $scope.choosen_drop[item.index] = false;
                    if (!item.formData[0])
                        item.formData[0] = {};
                    item.formData[0].name = item.file.name.substring(0, item.file.name.lastIndexOf('.'));
                    item.formData[0].hash = Date.now();
                    item.formData[0].folder_id = 0;
                    item.formData[0].category = $scope.categories[0];
                    $scope.uploader_queue_length++;
                    $scope.uploader.uploadAll();
                },
                onBeforeUploadItem: function (item) {
                    item.formData[0].category_id = 'module';
                },
                onSuccessItem: function (item) {
                    GlobalService.clearErrors();
                    $scope.loaded++;
                },
                onErrorItem: function (item, response, status, headers) {
                    GlobalService.clearErrors();
                    GlobalService.addErrors(response, 'document');
                },
                onCompleteAll: function () {
                    if ($scope.loaded == $scope.uploader_queue_length) {
                        swal({
                            title: "File caricati con successo!",
                            text: "",
                            type: "success",
                            showCancelButton: false
                        }, function () {
                            GlobalService.clearErrors();
                        }
                        );
                    } else {
                        $scope.uploader_queue_length = 0;
                        if ($scope.loaded == 0) {
                            SweetAlert.swal("I File non sono stati caricati!", "", "error");
                        } else {
                            SweetAlert.swal("File  caricati!", "", "success");
                        }
                    }
                }
            });

            PromotionById.query({id: promotionId}).$promise.then(function (data) {
                var singleNews = data[0];

                if (singleNews.imgm) {
                    var myImage = new Image(100, 100);
                    myImage.src = GlobalService.apiBaseUrl + '/documents/get_imagemodule/' + singleNews.imgm;

                    $scope.uploader.queue.push({
                        file: {
                            name: singleNews.imgm,
                            size: singleNews.sizefilem,
                            file_http: myImage.src
                        },
                        isUploaded: false,
                        isSuccess: false
                    });
                }
            });
            $scope.removeItem = (function (item) {
                try {
                    $scope.uploader.removeFromQueue(item);
                } catch (e) {
                        console.log(e);
                }
            });

            $('#wrapper_mob .file-placeholder').on('dragleave', function () {
                $('#wrapper_mob div[nv-file-drop]').removeClass('nv-file-over');
            });

            $scope.choosenSelect = function (folder, item) {
                if (folder) {
                    if (!item.formData[0])
                        item.formData[0] = {};
                    item.formData[0].folder_id = folder.id;
                    item.formData[0].folder_name = folder.name;
                }
                $scope.choosen_drop[item.formData[0].hash] = false;
            };

            $scope.toggleChoosen = function (item) {
                $scope.choosen_drop[item.formData[0].hash] = !$scope.choosen_drop[item.formData[0].hash];
            };
            //End Image Upload Mobile
            //start Image Upload Desktop
            $scope.document_d = new Document();
            $scope.choosen_drop_d = [];
            $scope.uploader_d_queue_length = 0;
            $scope.loaded_d = 0;

            $scope.uploader_d = new FileUploader({

                url: GlobalService.apiBaseUrl + '/documents',
                alias: 'attachment',
                scope: $scope,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                filters: [
                    {
                        name: 'filetype',
                        fn: function (item /*{File|FileLikeObject}*/, options) {
                            var type = '|' + item.name.slice(item.name.lastIndexOf('.') + 1).toLowerCase() + '|';
                            var allowed_types = [
                                'jpg', 'png', 'jpeg', 'bmp', 'gif',
                            ];
                            return ('|' + allowed_types.join('|') + '|').indexOf(type) !== -1;
                        }
                    }
                ],
                onAfterAddingFile: function (item) {
                    if ($scope.uploader_d.queue.length == 2)
                    {
                        var index = 0;
                        $scope.uploader_d.queue.splice(index, 1);
                    }
                    $scope.loaded_d = 0;
                    $scope.choosen_drop_d[item.index] = false;
                    if (!item.formData[0])
                        item.formData[0] = {};
                    item.formData[0].name = item.file.name.substring(0, item.file.name.lastIndexOf('.'));
                    item.formData[0].hash = Date.now();
                    item.formData[0].folder_id = 0;
                    item.formData[0].category = $scope.categories[0];
                    $scope.uploader_d_queue_length++;

                    $scope.uploader_d.uploadAll();
                },
                onBeforeUploadItem: function (item) {
                    item.formData[0].category_id = 'module';
                },
                onSuccessItem: function (item) {
                    GlobalService.clearErrors();
                    $scope.loaded_d++;
                },
                onErrorItem: function (item, response, status, headers) {
                    GlobalService.clearErrors();
                    GlobalService.addErrors(response, 'document');
                },
                onCompleteAll: function () {
                    if ($scope.loaded_d == $scope.uploader_d_queue_length) {
                        swal({
                            title: "File caricati con successo!",
                            text: "",
                            type: "success",
                            showCancelButton: false
                        }, function () {
                            GlobalService.clearErrors();
                        });
                    } else {
                        $scope.uploader_d_queue_length = 0;
                        if ($scope.loaded_d == 0) {
                            SweetAlert.swal("I File non sono stati caricati!", "", "error");
                        } else {
                            SweetAlert.swal("File  caricati!", "", "success");
                        }
                    }
                }
            });
            PromotionById.query({id: promotionId}).$promise.then(function (data) {
                var singleNews = data[0];
                if (singleNews.imgd) {
                    var myImage = new Image(100, 100);
                    myImage.src = GlobalService.apiBaseUrl + '/documents/get_imagemodule/' + singleNews.imgd;
                    $scope.uploader_d.queue.push({
                        file: {
                            name: singleNews.imgd,
                            size: singleNews.sizefiled,
                            file_http: myImage.src

                        },
                        isUploaded: false,
                        isSuccess: false
                    });
                }
            });
            $scope.removeItemD = (function (item) {
                try {
                    $scope.uploader_d.removeFromQueue(item);
                } catch (e) {
                    console.log(e);
                }
            });

            $('#wrapper_des .file-placeholder').on('dragleave', function () {
                $('#wrapper_des div[nv-file-drop]').removeClass('nv-file-over');

            });

            $scope.choosenSelect_d = function (folder, item) {
                if (folder) {
                    if (!item.formData[0])
                        item.formData[0] = {};
                    item.formData[0].folder_id = folder.id;
                    item.formData[0].folder_name = folder.name;
                }
                $scope.choosen_drop_d[item.formData[0].hash] = false;
            };

            $scope.toggleChoosen_d = function (item) {
                $scope.choosen_drop_d[item.formData[0].hash] = !$scope.choosen_drop_d[item.formData[0].hash];
            };

            //End Image Upload Desktop
            $scope.editNews = function (notizia) {
                $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'Modifica Notizia',
                    templateUrl: 'views/news/edit.html',
                    controller: 'NewsEditCtrl',
                    resolve: {
                        category: function () {
                            return category;
                        }
                    }
                });
            };
        });
   