angular.module('cofidis.controllers')
    .controller('SlidesIndexCtrl', function ($scope, $rootScope, $uibModal, $state, Slide, SweetAlert, GlobalService) {
        //Get last  id
        $scope.$root.lastslide = Slide.query();
        //Checks presence of no records in table
        Slide.query().$promise.then(function (data) {
            $scope.$root.lastslide = data;
            console.log($scope.$root.lastslide);
            if (!data[0].id) {

                data[0].id = 0;
                data[0].video1 = "";
                data[0].video2 = "";
                data[0].video3 = "";
                data[0].video4 = "";
                data[0].video5 = "";
            }
        });

        $scope.updateSlide = function (slide) {

		console.log(slide);
		
		var message = "Video aggiornati!";
            //uploader mobile

            message = "Video aggiornati!";

            var hasError=false;

            if  ((getId(slide.video1)==null && slide.video1.length>0) 
                    || (getId(slide.video2)==null && slide.video2.length>0) 
                    || (getId(slide.video3)==null && slide.video3.length>0)
                    || (getId(slide.video4)==null && slide.video4.length>0)
                    || (getId(slide.video5)==null && slide.video5.length>0)
                )
                {hasError = true;}



            //angular resource
            if(hasError===false)
            {
                    slide.$update(function () {
                    // $state.reload();
                    // console.log(notizia.id);
                    GlobalService.clearErrors();
                    $("#edit_slide div.field").removeClass("has-error");
                    SweetAlert.swal(message, "", "success");
                }, function (xhr) {
                    GlobalService.clearErrors();
                    

                });
            }

            else

               {

               // GlobalService.clearErrors();
                jQuery('.error-summary ul').html('');
                jQuery(".error-summary").removeClass("hidden");
                jQuery('.error-summary ul').append('<li>' + "Sono supportati solo i video di Vimeo con il seguente formato: https://vimeo.com/XXXXXXXXX" + '</li>');
               } 


        };




        //Checks presence of files saved related with the news
        Slide.query().$promise.then(function (data) {
            var singleSlide = data[0];

            if (singleSlide.video1) {

            }
        });


        function getId( url)
        {
              //var url = "http://www.vimeo.com/7058755";
            var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;

            if (typeof url != "undefined" || url != null)
                var match = url.match(regExp);
            else
                var match = null;
            if (match){
                return "https://player.vimeo.com/video/" + match[2];
            }else{
                return null;
            }
        }


    })