angular.module('cofidis.controllers')
  .controller('LoginCtrl', function($scope, $http, $state, $window, GlobalService) {
    $scope.loggedin = false;
    $scope.currentNavItem = "login-partner";

    if (GlobalService.resetPasswordResult !== null) {
      $scope.resetPasswordResult = GlobalService.resetPasswordResult;
    }
    $scope.login = function(){
      $('.alert-danger').addClass('hidden');
      var gs = GlobalService;
      var xhr = $http({
        method: 'POST',
        url: GlobalService.apiBaseUrl+'/users/login',
        data: {email: this.email, password: this.password}
      });
      xhr.then(
        function successCallback(response){
          if(response.data.role_id != 1){
            $window.location.href = "#/dashboard";
            $window.location.reload();
          }
          else{
            $window.location.href = "#/index/documents";
            $window.location.reload();
          }
        },
        function errorCallback(response){
          $('.alert-danger').removeClass('hidden');
        }
      );
    }

  });
