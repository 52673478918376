angular.module('cofidis.controllers')
  .controller('ResetPasswordCtrl', function($scope, $http, $state, GlobalService) {
    $scope.loading = false;
    $scope.resetPassword = function(){
      $scope.loading = true;
      $http({
        method: 'POST',
        url: GlobalService.apiBaseUrl+'/users/forgot_password',
        data:{
          email: jQuery("input[name=email]").val()
        }
      }).then(function successCallback(response) {
          $scope.loading = false;
          GlobalService.resetPasswordResult = true;
          $state.go("login", {}, { reload: true });
        }, function errorCallback(response) {
          $scope.loading = false;
          GlobalService.resetPasswordResult = false;
          $state.go("login", {}, { reload: true });
        });
    };
  })