angular.module('cofidis.services')
  .factory('Document', function($resource,GlobalService){
    return $resource(GlobalService.apiBaseUrl+'/documents/:id', { id: '@id' }, {
      update: {
        method: 'PUT' 
      }
    })





  });




