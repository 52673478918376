angular.module('cofidis.controllers')
  .controller('MainCtrl', function($scope, $timeout, GlobalService) {

    $scope.current_user = GlobalService.getUser();
    $scope.startYear = 2016;
    $scope.endYear = new Date().getFullYear();
	  $scope.apiBaseUrl = GlobalService.apiBaseUrl;
    $scope.blogHome = GlobalService.blogHome;
    if($scope.endYear == $scope.startYear) $scope.endYear++;
    GlobalService.onUserChange(function(){
      $scope.current_user = GlobalService.getUser();
    });

    $scope.iboxShowhide = function($event){
      var $element = angular.element($event.currentTarget);
      var ibox = $element.closest('div.ibox');
      var icon = $element.find('i:first');
      var content = ibox.find('div.ibox-content');
      content.slideToggle(200);
      // Toggle icon from up to down
      icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      ibox.toggleClass('').toggleClass('border-bottom');
      $timeout(function () {
          ibox.resize();
          ibox.find('[id^=map-]').resize();
      }, 50);
    },

    $scope.iboxClose = function($event){
      var $element = angular.element($event.currentTarget);
      var ibox = $element.closest('div.ibox');
      ibox.remove();
    }

  });
