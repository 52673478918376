/**
 * INSPINIA - Responsive Admin Theme
 *
 */
(function () {
    angular.module('cofidis.controllers', ['ngMaterial']);
    angular.module('cofidis.services', []);
    angular.module('cofidis.directives', []);
    angular.module('cofidis.filters', []);
    angular.module('cofidis', [
        'ui.router',                    // Routing
        'oc.lazyLoad',                  // ocLazyLoad
        'ui.bootstrap',                 // Ui Bootstrap
        'oitozero.ngSweetAlert',
        'pascalprecht.translate',
        'ngResource',
        'angular.filter',
        'angularFileUpload',
		'ngWig',
        'ngAnimate',
        'ngAria',
        'ngMaterial',
        'dndLists',
        'cofidis.controllers',
        'cofidis.services',
        'cofidis.directives',
        'cofidis.filters'
    ]);

})();



