angular.module('cofidis.controllers')
  .controller('CategoriesIndexCtrl', function($scope, $uibModal, $state, Category, SweetAlert) {

    $scope.$root.categories = Category.query();

    $scope.deleteCategory = function(category){
      SweetAlert.swal(
        {
          title: "Sei sicuro?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Si, cancella!",
          cancelButtonText: "Annulla",
          closeOnConfirm: false
        },
        function(isConfirm){
          if(isConfirm){
            category.$delete(function() {
              SweetAlert.swal("Categoria cancellata!", "", "success");
              var index = $scope.$root.categories.indexOf(category);
              $scope.$root.categories.splice(index, 1);
            });
          }
        }
      );
    };

    $scope.newCategory = function(){
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'Nuova Categoria',
        templateUrl: 'views/categories/new.html',
        controller: 'CategoryNewCtrl'
      });
    }

    $scope.editCategory = function(category){
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'Modifica Categoria',
        templateUrl: 'views/categories/edit.html',
        controller: 'CategoryEditCtrl',
        resolve: {
          category: function () {
            return category;
          }
        }
      });
    }

  })
  .controller('CategoryNewCtrl', function($scope, $uibModalInstance, $state, Category, GlobalService, SweetAlert) {

    $scope.cancel = function(){
      $uibModalInstance.dismiss('cancel');
    };

    $scope.category = new Category();
    $scope.roles = GlobalService.getRoles();
    $uibModalInstance.rendered.then(function(){
      setTimeout(function(){
        $('#category_role_ids').chosen();
      }, 10);
    });

    $scope.createCategory = function() {
      GlobalService.clearErrors("#new_category");
      $scope.category.$save(function() {
        $scope.$root.categories.push(angular.copy($scope.category));
        $uibModalInstance.dismiss('cancel');
        SweetAlert.swal("Categoria creata!", "", "success");
      }, function(xhr){
        GlobalService.addErrors(xhr.data, 'category');
      });
    };

  })
  .controller('CategoryEditCtrl', function($scope, $uibModalInstance, $state, category, Category, GlobalService, SweetAlert) {

    $scope.cancel = function(){
      $uibModalInstance.dismiss('cancel');
    };

    $scope.category = angular.copy(category);
    $scope.index = $scope.$root.categories.indexOf(category);
    $scope.roles = GlobalService.getRoles();
    $uibModalInstance.rendered.then(function(){
      setTimeout(function(){
        $('#category_role_ids').chosen();
      }, 10);
    });

    $scope.updateCategory = function() {
      GlobalService.clearErrors("#edit_category");
      $scope.category.$update(function() {
        $scope.$root.categories[$scope.index] = angular.copy($scope.category)
        $uibModalInstance.dismiss('cancel');
        SweetAlert.swal("Categoria modificata!", "", "success");
      }, function(xhr){
        GlobalService.addErrors(xhr.data, 'category');
      });
    };

  });