angular.module('cofidis.services')
  .factory('GlobalService', function($sce, $state, $http) {
    return {
      apiBaseUrl: window.location.protocol + '//' + window.location.host,
      blogHome: null,
      useFolders: true,
      resetPasswordResult: null,
      user: '',
      currentUserCallback: [],
      currentFolder: null,
      currentFolderCallback: [],
      currentFolderBreadCrumbs: [],
      folders: [],
      foldersCallback: [],
      roles: [],
      news: [],
      slides: [],
      rolesCallback: [],

      setApiBaseUrl: function(_value){
        this.apiBaseUrl = _value;
      },

      htmlSafe: function(str) {
        return $sce.trustAsHtml(str);
      },

      setUser: function(_user) {
        this.user = _user;
        for(var i=0; i<this.currentUserCallback.length; i++){
          this.currentUserCallback[i]();
        }
      },

      getUser: function() {
        return this.user;
      },

      onUserChange: function(callback){
        if(typeof callback === "function"){
          this.currentUserCallback.push(callback);
        }
      },

      setCurrentFolder: function(_currentFolder) {
        this.currentFolder = _currentFolder;
        this.setCurrentFolderBreadCrumbs();
        for(var i=0; i<this.currentFolderCallback.length; i++){
          this.currentFolderCallback[i]();
        }
      },

      getCurrentFolder: function() {
        return this.currentFolder;
      },

      onCurrentFolderChange: function(callback){
        if(typeof callback === "function"){
          this.currentFolderCallback.push(callback);
        }
      },

      setCurrentFolderBreadCrumbs: function(){
        if(this.currentFolder){
          var ids = this.currentFolder.path.split('/');
          var that = this;
          var ff = this.folders;
          this.currentFolderBreadCrumbs = ['Home', 'Documenti'];
          if(this.useFolders){
            for(var i = 0; i < ids.length; i++){
              $.each(ff, function(idx, f){
                if(f.id == ids[i]){
                  that.currentFolderBreadCrumbs.push(f.name);
                  ff = f.children_recursive;
                  return;
                }
			});
            }
          }
        }
      },

      getCurrentFolderBreadCrumbs: function() {
        return this.currentFolderBreadCrumbs;
      },

      getFolders: function(){
        return this.folders;
      },

      setFolders: function(_folders){
        this.folders = _folders;
        for(var i=0; i<this.foldersCallback.length; i++){
          this.foldersCallback[i]();
        }
      },

      onFoldersChange: function(callback){
        if(typeof callback === "function"){
          this.foldersCallback.push(callback);
        }
      },

      resetFolderCallbacks: function(){
        if(this.currentFolderCallback.length > 0)
          this.currentFolderCallback = [];
        if(this.foldersCallback.length > 0)
          this.foldersCallback = [];
      },

      getRoles: function(){
        return this.roles;
      },

      setRoles: function(_roles){
        this.roles = _roles;
      },

      clearErrors: function(formSelector){
		$('.error-summary').addClass('hidden');  
        $('.error-summary ul').html('');
        $(formSelector + ' .field').removeClass('has-error');
      },

      addErrors: function(errors, resource){
        if(!$.isEmptyObject(errors)){
          $.each(errors, function(field, field_errors){
            $('#' + resource + '_' + field).parents('.field').addClass('has-error');
            $.each(field_errors, function(idx, error){
              $('.error-summary ul').append('<li>' + error + '</li>');
		  });
          });
          $('.error-summary').removeClass('hidden');
        }
      },

      authCheck: function(admin){
        if(typeof admin == 'undefined') admin = true;
        var xhr = $http({method: 'GET', url: this.apiBaseUrl+'/users/isloggedin'});
        var that = this;
        xhr.then(
          function successCallback(response) {
              that.setUser(response.data);
              if(admin && response.data.role_id != 1){
                console.log("Access granted. Not admin redirect to normal user");
                $state.go("documents", {}, { reload: true });
                return;
              }
              else{
                console.log("Access granted");
                return;
              }
          },
          function errorCallback(response) {
              console.log("Redirect to login page");
              $state.go("login", {}, { reload: true });
              return;
          }
        );
      }

  };
  });
