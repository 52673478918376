angular.module('cofidis.controllers')
        .controller('DocumentsIndexCtrl', function ($http, $scope, $uibModal, $state, Document, Category, SweetAlert, GlobalService) {
            if (GlobalService.getCurrentFolder()) {
                /*$scope.$root.documents = Document.query({folder_id: GlobalService.getCurrentFolder().id})*/
                $http.get(GlobalService.apiBaseUrl + "/documents/bysector/", {cache: false})
                        .then(function (response) {

                            $scope.$root.documents = response.data;
                        });
                $scope.breadcrumbs = GlobalService.getCurrentFolderBreadCrumbs();
            }
            GlobalService.onCurrentFolderChange(function () {
                $http.get(GlobalService.apiBaseUrl + "/documents/bysector/", {cache: false})
                        .then(function (response) {

                            $scope.$root.documents = response.data;
                        });
                $scope.breadcrumbs = GlobalService.getCurrentFolderBreadCrumbs();
            });
            $scope.useFolders = GlobalService.useFolders;
            $scope.categories = Category.query();
            $scope.callbackMoved = function (index, section) {
                section.splice(index, 1);
                $http.post(GlobalService.apiBaseUrl + "/documents/dorder", section, {cache: false})
                        .then(
                                function (response) {
                                },
                                function (response) {
                                }
                        );
            };
            $scope.setCurrentFolder = function (folder) {
                GlobalService.setCurrentFolder(folder);
            };

            $scope.deleteDocument = function (_document) {
                SweetAlert.swal(
                        {
                            title: "Sei sicuro?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Si, cancella!",
                            cancelButtonText: "Annulla",
                            closeOnConfirm: false
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                sectionid = _document.section_id;
                                id = _document.id;
                                $http.get(GlobalService.apiBaseUrl + "/documents/removedoc/" + id, null, {cache: false})
                                        .then(function (response) {
                                            SweetAlert.swal("Documento cancellato!", "", "success");
                                            var index = $scope.$root.documents[sectionid].indexOf(_document);
                                            $scope.$root.documents[sectionid].splice(index, 1);
                                        });
                               }
                        }
                );
            };
        })
        .controller('DocumentNewCtrl', function ($scope, $http, $state, Document, GlobalService, SweetAlert, Category, FileUploader) {
            $scope.document = new Document();
            $scope.folders = GlobalService.getFolders();
            $scope.categories = Category.query();
            $scope.sections = [{'id': 0, 'name': 'PAGODIL / PA'},{'id': 4, 'name': 'Acquiring'}, {'id': 1, 'name': 'SAA'}, {'id': 2, 'name': 'Prestito Finalizzato'},{'id': 3, 'name': 'Antiriciclaggio'}];
            $scope.choosen_drop = [];
            $scope.useFolders = GlobalService.useFolders;
            $scope.uploader_queue_length = 0;
            $scope.loaded = 0;
            $scope.apiBaseUrl = GlobalService.apiBaseUrl;
            $scope.blogHome = GlobalService.blogHome;

            $http.get(GlobalService.apiBaseUrl + "/roles", {cache: false})
                    .then(function (response) {
                        $scope.roles = response.data;
                    });
            $scope.uploader = new FileUploader({
                url: GlobalService.apiBaseUrl + '/documents',
                alias: 'attachment',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                filters: [
                    {
                        name: 'filetype',
                        fn: function (item /*{File|FileLikeObject}*/, options) {
                            var type = '|' + item.name.slice(item.name.lastIndexOf('.') + 1).toLowerCase() + '|';
                            var allowed_types = [
                                'jpg', 'png', 'jpeg', 'bmp', 'gif',
                                'pdf', 'xls', 'xlsx', 'doc', 'docx',
                                'ppt', 'pptx'
                            ];
                            return ('|' + allowed_types.join('|') + '|').indexOf(type) !== -1;
                        }
                    }
                ],
                onAfterAddingFile: function (item) {
                    $scope.loaded = 0;
                    $scope.choosen_drop[item.index] = false;
                    if (!item.formData[0])
                        item.formData[0] = {};
                    item.formData[0].name = item.file.name.substring(0, item.file.name.lastIndexOf('.'));
                    item.formData[0].hash = Date.now();
                    item.formData[0].folder_id = $scope.useFolders ? GlobalService.getCurrentFolder().id : $scope.folders[0].id;
                    item.formData[0].category = $scope.categories[0];
                    item.formData[0].section = $scope.sections[0];
                    item.formData[0].role = $scope.roles;
                    $scope.uploader_queue_length++;
                    setTimeout(function () {
                        jQuery('#role_ids').chosen();
                    }, 100);

                },
                onBeforeUploadItem: function (item) {
                    item.formData[0].category_id = item.formData[0].category.id;
                    item.formData[0].section_id = item.formData[0].section.id;
                    item.formData[0].role_ids = item.formData[0].role;
                },
                onSuccessItem: function (item) {
                    $scope.loaded++;
                    item.remove();
                },
                onErrorItem: function (item, response, status, headers) {
                    GlobalService.addErrors(response, 'document');
                },
                onCompleteAll: function () {
                    if ($scope.loaded == $scope.uploader_queue_length) {
                        swal({
                            title: "Documenti caricati con successo!",
                            text: "",
                            type: "success",
                            showCancelButton: false
                        }, function () {
                            $state.go("index.documents", {}, {reload: true})
                        }
                        );
                    } else {
                        $scope.uploader_queue_length = 0;
                        if ($scope.loaded == 0) {
                            SweetAlert.swal("I documenti non sono stati caricati!", "", "error");
                        } else {
                            SweetAlert.swal("Alcuni documenti non sono stati caricati!", "", "warning");
                        }
                    }
                }
            });
            $('.file-placeholder').on('dragleave', function () {
                $('div[nv-file-drop]').removeClass('nv-file-over');
            });


        })
        .controller('DocumentsUserCtrl', function ($scope, $http, Document, GlobalService, News, $sce, Slide) {
            $scope.apiBaseUrl = GlobalService.apiBaseUrl;
            $scope.blogHome = GlobalService.blogHome;
            $scope.refreshTabs = function (event) {
                var catName = jQuery(event.target).attr("data_cat");
                jQuery(event.target).parent().parent().find(".tab_button").removeClass("active");
                jQuery(event.target).addClass("active");
                console.log(Document);

                var dataSection = jQuery(event.target).attr("data-section");
                jQuery(".categories-list[data-section=" + dataSection + "]").hide();

                var target = jQuery(".categories-list[data-section=" + dataSection + "]").find("li[data-cat=" + catName + "]");
                 if (target.length){
                        target.parent().fadeIn('slow');

                }else{
                   jQuery(".categories-list[data-section=" + dataSection + "]").find("li[data-cat=" + catName + 'Empty' +"]").parent().fadeIn('slow');
                }


            };

            $scope.renderHtml = function (htmlCode) {
                return $sce.trustAsHtml(htmlCode);
            };
            //Get last news id
            $scope.lastnews = News.query();
            $scope.sections = [{id: 0, name: "Pagodil / PA"}, {id: 0, name: "SAA"}, {id: 0, name: "Prestito Finalizzato"},{'id': 3, 'name': 'Antiriciclaggio'}, {id: 0, name: "Acquiring"}];
            $scope.videos = [];
            Slide.query().$promise.then(function (data) {
                var singleSlide = data[0];
                if (singleSlide.video1) {
                    $scope.videos.push($sce.trustAsResourceUrl(getId(singleSlide.video1)));
                }
                if (singleSlide.video2) {
                    $scope.videos.push($sce.trustAsResourceUrl(getId(singleSlide.video2)));
                }
                if (singleSlide.video3) {
                    $scope.videos.push($sce.trustAsResourceUrl(getId(singleSlide.video3)));
                }
                if (singleSlide.video4) {
                    $scope.videos.push($sce.trustAsResourceUrl(getId(singleSlide.video4)));
                }
                if (singleSlide.video5) {
                    $scope.videos.push($sce.trustAsResourceUrl(getId(singleSlide.video5)));
                }
            });
            function getId(url)
            {
                var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
                var match = url.match(regExp);
                if (match) {
                    return "https://player.vimeo.com/video/" + match[2];
                } else {
                    return null;
                }
            }
            $scope.apiBaseUrl = GlobalService.apiBaseUrl;
            $scope.blogHome = GlobalService.blogHome;
            $scope.loggedin = true;
            $scope.documentsSections = [];
            $http.get(GlobalService.apiBaseUrl + "/documents/bysector/", {cache: false})
                    .then(function (response) {
                        $scope.documentsSections = response.data;
                    });
            $scope.perPage = 3;
            $scope.perMinimun = 1;
            $scope.show = {};
            $scope.showMore = function (category) {
                $scope.show[category] = true;
            };
        })
        .controller('DashUserCtrl', function ($scope, $http, Document, GlobalService, News, Module, Portale,Promotions, $sce, Slide) {
            $scope.renderHtml = function (htmlCode) {
                return $sce.trustAsHtml(htmlCode);
            };
            //Get last news id
            $scope.lastnews = News.query();

            $scope.modules = Module.query();
            $scope.portali = Portale.query();
            $scope.promotions = Promotions.query();

            console.log($scope.modules);
            var portaleId = 1;
            $http.get(GlobalService.apiBaseUrl + "/portale/portaleinfo/" + portaleId, {cache: false})
                    .then(function (response) {
                        console.log(response);
                        $scope.$root.portaleInfo = response.data;
                    });

            console.log($scope.$root.portaleInfo);
            $scope.videos = [];
            Slide.query().$promise.then(function (data) {
                var singleSlide = data[0];
                if (singleSlide.video1) {
                    $scope.videos.push($sce.trustAsResourceUrl(getId(singleSlide.video1)));
                }
                if (singleSlide.video2) {
                    $scope.videos.push($sce.trustAsResourceUrl(getId(singleSlide.video2)));
                }
                if (singleSlide.video3) {
                    $scope.videos.push($sce.trustAsResourceUrl(getId(singleSlide.video3)));
                }
                if (singleSlide.video4) {
                    $scope.videos.push($sce.trustAsResourceUrl(getId(singleSlide.video4)));
                }
                if (singleSlide.video5) {
                    $scope.videos.push($sce.trustAsResourceUrl(getId(singleSlide.video5)));
                }
            });
            function getId(url)
            {
                //var url = "http://www.vimeo.com/7058755";
                var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;

                var match = url.match(regExp);

                if (match) {
                    return "https://player.vimeo.com/video/" + match[2];
                } else {
                    return null;
                }
            }

            // $scope.thisCanBeusedInsideNgBindHtml = $sce.trustAsHtml(someHtmlVar);

            $scope.apiBaseUrl = GlobalService.apiBaseUrl;
            $scope.blogHome = GlobalService.blogHome;
            // alert($scope.apiBaseUrl);

            $scope.loggedin = true;
            $scope.documents = [];
            $scope.documents = Document.query();
            $scope.perPage = 3;
            $scope.show = {};
            $scope.showMore = function (category) {
                $scope.show[category] = true;
            };
        });
