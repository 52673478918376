angular.module('cofidis.services')
  .factory('Promotions', function($resource,GlobalService){
    
	return $resource(GlobalService.apiBaseUrl+'/promotion/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });
  })

.factory('PromotionById', function( $resource , GlobalService ) {
    return $resource(GlobalService.apiBaseUrl+'/promotion/PromotionById', {}, {

       update: {
        method: 'PUT'
      }
      ,
        query: {
            method : 'GET',
            params : {id: ""},
            isArray: true
        }
    })
});



  ;