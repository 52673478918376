angular.module('cofidis.controllers')
  .controller('UsersIndexCtrl', function($scope, $uibModal, $state, User, Role, SweetAlert) {

    $scope.$root.users = User.query();

    $scope.deleteUser = function(user){
      SweetAlert.swal(
        {
          title: "Sei sicuro?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Si, cancella!",
          cancelButtonText: "Annulla",
          closeOnConfirm: false
        },
        function(isConfirm){
          if(isConfirm){
            user.$delete(function() {
              SweetAlert.swal("Utente cancellato!", "", "success");
              var index = $scope.$root.users.indexOf(user);
              $scope.$root.users.splice(index, 1);
            });
          }
        }
      );
    };

    $scope.newUser = function(){
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'Nuova Utente',
        templateUrl: 'views/users/new.html',
        controller: 'UserNewCtrl',
      });
    }

    $scope.editUser = function(user){
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'Modifica Utente',
        templateUrl: 'views/users/edit.html',
        controller: 'UserEditCtrl',
        resolve: {
          user: function () {
            return user;
          }
        }
      });
    }

  })
  .controller('UserNewCtrl', function($scope, $uibModalInstance, $state, User, GlobalService, SweetAlert) {

    $scope.cancel = function(){
      $uibModalInstance.dismiss('cancel');
    };

    $scope.user = new User();
    $scope.roles = GlobalService.getRoles();

    $scope.createUser = function() {
      GlobalService.clearErrors("#new_user");
      var role = $scope.user.role;
      $scope.user.role_id = role.id;
      $scope.user.$save(function(_u) {
        $scope.user.role = role;
        $scope.$root.users.push(angular.copy($scope.user));
        $uibModalInstance.dismiss('cancel');
        SweetAlert.swal("Utente creato!", "", "success");
      }, function(xhr){
        GlobalService.addErrors(xhr.data, 'user');
      });
    };

  })
  .controller('UserEditCtrl', function($scope, $uibModalInstance, $state, user, User, GlobalService, SweetAlert) {

    $scope.cancel = function(){
      $uibModalInstance.dismiss('cancel');
    };

    $scope.roles = GlobalService.getRoles();
    $scope.user = angular.copy(user);
    $scope.index = $scope.$root.users.indexOf(user);

    $scope.updateUser = function() {
      GlobalService.clearErrors("#edit_user");
      var role = $scope.user.role;
      $scope.user.role_id = role.id;
      $scope.user.$update(function() {
        $scope.user.role = role;
        $scope.$root.users[$scope.index] = angular.copy($scope.user)
        if(GlobalService.getUser().id == $scope.user.id)
          GlobalService.setUser(angular.copy($scope.user));
        $uibModalInstance.dismiss('cancel');
        SweetAlert.swal("Utente modificato!", "", "success");
      }, function(xhr){
        GlobalService.addErrors(xhr.data, 'user');
      });
    };

  });