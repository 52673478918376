angular.module('cofidis.services')
  .factory('News', function($resource,GlobalService){
    
	return $resource(GlobalService.apiBaseUrl+'/news/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });
  })

.factory('NewsByTitle', function( $resource , GlobalService ) {
    return $resource(GlobalService.apiBaseUrl+'/news/getNewsByTitle', {}, {
        query: {
            method : 'GET',
            params : {titleNews: ""},
            isArray: false
        }
    })
});



  ;