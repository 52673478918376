/**
 * INSPINIA - Responsive Admin Theme
 *
 * Inspinia theme use AngularUI Router to manage routing and views
 * Each view are defined as state.
 * Initial there are written state for all view in theme.
 *
 */

// $translate('app.error').then(function (headline) {
//   $scope.headline = headline;
// }, function (translationId) {
//   $scope.headline = translationId;
// });

function config($stateProvider, $urlRouterProvider, $ocLazyLoadProvider, $provide, $translateProvider) {


    // if( window.location.host === 'agenti.cofidis-retail.it' && window.location === window.parent.location){
    //     window.location.href = "https://www.cofidis-retail.it/area-partner/area-agenti/";
    // }

    $urlRouterProvider.otherwise("/login");

    $ocLazyLoadProvider.config({
        // Set to true if you want to see what and when is dynamically loaded
        debug: false
    });

    // Set different Apibaseurl for current baseUrl

    //redirect if is not in iframe

    var apiBaseUrl = window.location.protocol + '//' + window.location.host;

    $translateProvider.useStaticFilesLoader({
                prefix: apiBaseUrl + '/js/lang/',
                suffix: '.json'
            })
            .preferredLanguage(window.locale);
    $stateProvider
            .state('login', {
                url: "/login",
                views: {
                    main: {
                        controller: "LoginCtrl",
                        templateUrl: "views/login/form.html"
                    }
                },
                onEnter: function ($http, $state, GlobalService) {

                    GlobalService.setApiBaseUrl(apiBaseUrl);
                    var xhr = $http({method: 'GET', url: GlobalService.apiBaseUrl + '/users/isloggedin'});
                    xhr.then(
                            function successCallback(response) {
                                console.log("Already logged in");
                                if (GlobalService.getUser().role_id != 1)
                                    $state.go("dashboard", {}, {reload: true});
                                else
                                    $state.go("index.users", {}, {reload: true});
                                return;
                            },
                            function errorCallback(response) {
                                $('body').addClass('gray-bg');
                                return;
                            }
                    );
                }
            })
            .state('documents', {
                url: "/documents",
                views: {
                    main: {
                        controller: "DocumentsUserCtrl",
                        templateUrl: "views/documents/user_tabbed.html"
                    }
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck(false);
                    }
                }
            })



            .state('dashboard', {
                url: "/dashboard",
                views: {
                    main: {
                        controller: "DashUserCtrl",
                        templateUrl: "views/users/userdash.html"
                    }
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck(false);
                    }
                }
            })


            .state('index.portali', {
                url: "/portali",
                views: {
                    inner: {
                        controller: "PortaleListCtrl",
                        templateUrl: "views/portales/list.html"
                    }
                },
                data: {
                    pageTitle: "Portali"
                },

                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck(false);
                    }
                }
            })


            .state('index.portali_new', {
                url: "/portali_new/{portaleId}",
                params: {
                    portaleId: null
                },
                views: {
                    inner: {
                        controller: "PortaleIndexCtrl",
                        templateUrl: "views/portales/index.html"
                    }
                },
                data: {
                    pageTitle: "Portale"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    }
                }
            })


            .state('index.portali_info', {
                url: "/portali_info/{portaleId}",
                params: {
                    portaleId: null
                },
                views: {
                    inner: {
                        controller: "PortaleInfoCtrl",
                        templateUrl: "views/portales/portalinfo.html"
                    }
                },
                data: {
                    pageTitle: "Portale"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    }
                }
            })

            .state('index.promotions', {
                url: "/promotions",
                views: {
                    inner: {
                        controller: "PromotionsListCtrl",
                        templateUrl: "views/promotions/list.html"
                    }
                },
                data: {
                    pageTitle: "Promotions"
                },

                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck(false);
                    }
                }
            })


            .state('index.promotions_new', {
                url: "/promotions_new/{promotionId}",
                params: {
                    promotionId: null
                },
                views: {
                    inner: {
                        controller: "PromotionsIndexCtrl",
                        templateUrl: "views/promotions/index.html"
                    }
                },
                data: {
                    pageTitle: "Promozioni"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    }
                }
            })


            .state('index.promotions_info', {
                url: "/promotions_info/{promotionId}",
                params: {
                    promotionId: null
                },
                views: {
                    inner: {
                        controller: "PromotionsInfoCtrl",
                        templateUrl: "views/promotions/promotionsinfo.html"
                    }
                },
                data: {
                    pageTitle: "Promozioni"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    }
                }
            })



            .state('index.oki', {
                url: "/oki",
                views: {
                    main: {
                        controller: "DocumentsUserCtrl",
                        templateUrl: "views/documents/oki.html"
                    }
                },
                resolve: {

                }

            })
            .state('reset_password', {
                url: "/reset_password",
                views: {
                    main: {
                        controller: "ResetPasswordCtrl",
                        templateUrl: "views/users/reset_password.html"
                    }
                }
            })
            .state('index', {
                url: "/index",
                abstract: true,
                views: {
                    main: {
                        controller: "MainCtrl",
                        templateUrl: "views/common/content.html"
                    }
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    }
                }
            })
            .state('index.documents', {
                url: "/documents",
                views: {
                    inner: {
                        controller: "DocumentsIndexCtrl",
                        templateUrl: "views/documents/indexsections.html"
                    }
                },
                data: {
                    pageTitle: "Documenti"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    },
                    setFolders: function ($state, GlobalService, Folder) {
                        var gs = GlobalService;
                        gs.resetFolderCallbacks();
                        Folder.query().$promise.then(function (data) {
                            gs.setFolders(data);
                            gs.setCurrentFolder(data[0]);
                        });
                    }
                }
            })
            .state('index.documents_new', {
                url: "/documents/new",
                views: {
                    inner: {
                        controller: "DocumentNewCtrl",
                        templateUrl: "views/documents/new.html"
                    }
                },
                data: {
                    pageTitle: "Nuovo documento"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    },
                    setFolders: function ($state, GlobalService, Folder) {
                        if (GlobalService.getFolders().length === 0) {
                            var gs = GlobalService;
                            gs.resetFolderCallbacks();
                            Folder.query().$promise.then(function (data) {
                                gs.setFolders(data);
                                gs.setCurrentFolder(data[0]);
                            });
                        }
                    }
                }
            })

            .state('index.faqs', {
                url: "/faqs",
                views: {
                    inner: {
                        controller: "FaqsIndexCtrl",
                        templateUrl: "views/faqs/index.html"
                    }
                },
                data: {
                    pageTitle: "Faqs"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    },
                    setRoles: function (GlobalService, Role) {
                        var gs = GlobalService;
                        Role.query().$promise.then(function (data) {
                            gs.setRoles(data);
                        });
                    }
                }
            })

            .state('index.categories', {
                url: "/categories",
                views: {
                    inner: {
                        controller: "CategoriesIndexCtrl",
                        templateUrl: "views/categories/index.html"
                    }
                },
                data: {
                    pageTitle: "Categorie"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    },
                    setRoles: function (GlobalService, Role) {
                        var gs = GlobalService;
                        Role.query().$promise.then(function (data) {
                            gs.setRoles(data);
                        });
                    }
                }
            })
            .state('index.roles', {
                url: "/roles",
                views: {
                    inner: {
                        controller: "RolesIndexCtrl",
                        templateUrl: "views/roles/index.html"
                    }
                },
                data: {
                    pageTitle: "Ruoli"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    }
                }
            })
            .state('index.news', {
                url: "/news",
                views: {
                    inner: {
                        controller: "NewsIndexCtrl",
                        templateUrl: "views/news/index.html"
                    }
                },
                data: {
                    pageTitle: "What's new"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    }
                }
            })


            .state('index.module', {
                url: "/module",
                views: {
                    inner: {
                        controller: "ModuleIndexCtrl",
                        templateUrl: "views/module/index.html"
                    }
                },
                data: {
                    pageTitle: "Module"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    }
                }
            })

            .state('index.video', {
                url: "/video",
                views: {
                    inner: {
                        controller: "SlidesIndexCtrl",
                        templateUrl: "views/slides/index.html"
                    }
                },
                data: {
                    pageTitle: "Video"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    }
                }
            })



            .state('index.users', {
                url: "/users",
                views: {
                    inner: {
                        controller: "UsersIndexCtrl",
                        templateUrl: "views/users/index.html"
                    }
                },
                data: {
                    pageTitle: "Utenti"
                },
                resolve: {
                    authCheck: function (GlobalService) {
                        GlobalService.authCheck();
                    },
                    setRoles: function (GlobalService, Role) {
                        var gs = GlobalService;
                        Role.query().$promise.then(function (data) {
                            gs.setRoles(data);
                        });
                    }
                }
            });

}

angular
        .module('cofidis')
        .config(config)
        .run(function ($rootScope, $state, $location, GlobalService) {
            $rootScope.$state = $state;
            GlobalService.useFolders = false;
        });