angular.module('cofidis.controllers')
  .controller('FoldersIndexCtrl', function($scope, $uibModal, $state, findByIdFilter, Folder, SweetAlert, GlobalService) {

    $scope.folders = GlobalService.getFolders();
    GlobalService.onFoldersChange(function(){
      $scope.folders = GlobalService.getFolders();
    })

    $scope.deleteFolder = function(folder){
      SweetAlert.swal(
        {
          title: "Sei sicuro?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Si, cancella!",
          cancelButtonText: "Annulla",
          closeOnConfirm: false
        },
        function(isConfirm){
          if(isConfirm){
            folder.$delete(function() {
              SweetAlert.swal("Cartella cancellata!", "", "success");
              var index = $scope.folders.indexOf(folder);
              $scope.folders.splice(index, 1);
            });
          }
        }
      );
    };

    $scope.newFolder = function(){
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'Nuova Cartella',
        templateUrl: 'views/folders/new.html',
        controller: 'FolderNewCtrl',
      });
    }

    $scope.editFolder = function(folder){
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'Modifica Cartella',
        templateUrl: 'views/folders/edit.html',
        controller: 'FolderEditCtrl',
        resolve: {
          folder: function () {
            // Questo perchè i figli non sono istanza di $resource valide
            return new Folder(folder);
          }
        }
      });
    }

  })
  .controller('FolderNewCtrl', function($scope, $uibModalInstance, $state, Folder, GlobalService, SweetAlert) {

    $scope.cancel = function(){
      $uibModalInstance.dismiss('cancel');
    };

    $scope.choosenSelect = function(folder){
      if(folder === null){
        $scope.folder.parent = null;
        $scope.folder.parent_id = null;
      }
      else{
        $scope.folder.parent = folder;
        $scope.folder.parent_id = folder.id;
      }
      $scope.choosen_drop = false;
    };

    $scope.toggleChoosen = function(){
      $scope.choosen_drop = !$scope.choosen_drop;
    }

    $scope.choosen_drop = false;
    $scope.folder = new Folder();
    $scope.folders = GlobalService.getFolders();

    $scope.createFolder = function() {
      GlobalService.clearErrors("#new_folder");
      $scope.folder.$save(function() {
        if(!$scope.folder.parent_id)
          $scope.folders.push(angular.copy($scope.folder));
        else{
          var ids = $scope.folder.parent.path.split('/');
          var ff = $scope.folders;
          for(var i=0; i < ids.length; i++){
            $.each(ff, function(idx, f){
              if(f.id == ids[i]){
                ff = f.children_recursive;
                return
              }
            })
          }
          ff.push(angular.copy($scope.folder));
        }
        $uibModalInstance.dismiss('cancel');
        SweetAlert.swal("Cartella creata!", "", "success");
      }, function(xhr){
        GlobalService.addErrors(xhr.data, 'folder');
      });
    };

  })
  .controller('FolderEditCtrl', function($scope, $uibModalInstance, $state, folder, Folder, GlobalService, SweetAlert) {

    $scope.cancel = function(){
      $uibModalInstance.dismiss('cancel');
    };

    $scope.choosenSelect = function(folder){
      if(folder === null){
        $scope.folder.parent = null;
        $scope.folder.parent_id = null;
        $scope.choosen_drop = false;
      }
      else{
        if(folder.id != $scope.folder.id){
          $scope.folder.parent = folder;
          $scope.folder.parent_id = folder.id;
          $scope.choosen_drop = false;
        }
      }
    };

    $scope.toggleChoosen = function(){
      $scope.choosen_drop = !$scope.choosen_drop;
    }

    $scope.folder = angular.copy(folder);
    $scope.folders = GlobalService.getFolders();
    $scope.updateFolder = function() {
      GlobalService.clearErrors("#edit_folder");
      $scope.folder.$update(function() {
        GlobalService.setFolders(Folder.query());
        $uibModalInstance.dismiss('cancel');
        SweetAlert.swal("Cartella modificata!", "", "success");
      }, function(xhr){
        GlobalService.addErrors(xhr.data, 'folder');
      });
    };

  });