function date() {
  return function(input) {
    var d = new Date(input);
    if(d)
      return d.toLocaleDateString(window.locale);
    else
      return input;
  }
};

function datetime() {
  return function(input) {
    var d = new Date(input);
    if(d)
      return d.toLocaleString(window.locale);
    else
      return input;
  }
};

angular.module('cofidis.filters')
    .filter('date', date)
    .filter('datetime', datetime);