angular.module('cofidis.services')
  .factory('Module', function($resource,GlobalService){
    
	return $resource(GlobalService.apiBaseUrl+'/module/:id', { id: '@id' }, {
      update: {
        method: 'PUT'
      }
    });
  })

.factory('ModuleByTitle', function( $resource , GlobalService ) {
    return $resource(GlobalService.apiBaseUrl+'/module/getModuleByTitle', {}, {
        query: {
            method : 'GET',
            params : {titleModule: ""},
            isArray: false
        }
    })
});



  ;