angular.module('cofidis.controllers')
  .controller('RolesIndexCtrl', function($scope, $uibModal, $state, Role, SweetAlert) {

    $scope.$root.roles = Role.query();

    $scope.deleteRole = function(role){
      SweetAlert.swal(
        {
          title: "Sei sicuro?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Si, cancella!",
          cancelButtonText: "Annulla",
          closeOnConfirm: false
        },
        function(isConfirm){
          if(isConfirm){
            role.$delete(function() {
              SweetAlert.swal("Ruolo cancellato!", "", "success");
              var index = $scope.$root.roles.indexOf(role);
              $scope.$root.roles.splice(index, 1);
            });
          }
        }
      );
    };

    $scope.newRole = function(){
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'Nuovo Ruolo',
        templateUrl: 'views/roles/new.html',
        controller: 'RoleNewCtrl',
      });
    }

    $scope.editRole = function(role){
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'Modifica Ruolo',
        templateUrl: 'views/roles/edit.html',
        controller: 'RoleEditCtrl',
        resolve: {
          role: function () {
            return role;
          }
        }
      });
    }

  })
  .controller('RoleNewCtrl', function($scope, $uibModalInstance, $state, Role, GlobalService, SweetAlert) {

    $scope.cancel = function(){
      $uibModalInstance.dismiss('cancel');
    };

    $scope.role = new Role();

    $scope.createRole = function() {
      GlobalService.clearErrors("#new_role");
      $scope.role.$save(function() {
        $scope.role.users = [];
        $scope.$root.roles.push(angular.copy($scope.role));
        $uibModalInstance.dismiss('cancel');
        SweetAlert.swal("Ruolo creato!", "", "success");
      }, function(xhr){
        GlobalService.addErrors(xhr.data, 'role');
      });
    };

  })
  .controller('RoleEditCtrl', function($scope, $uibModalInstance, $state, role, Role, GlobalService, SweetAlert) {

    $scope.cancel = function(){
      $uibModalInstance.dismiss('cancel');
    };

    $scope.role = angular.copy(role);
    $scope.index = $scope.$root.roles.indexOf(role);
    $scope.updateRole = function() {
      GlobalService.clearErrors("#edit_role");
      $scope.role.$update(function() {
        $scope.$root.roles[$scope.index] = angular.copy($scope.role)
        $uibModalInstance.dismiss('cancel');
        SweetAlert.swal("Ruolo modificato!", "", "success");
      }, function(xhr){
        GlobalService.addErrors(xhr.data, 'role');
      });
    };

  });